.navbar {
    margin-bottom: 20px;
  }
  
  .sidebar-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 120px); /* Subtracting the height of the navbar and margin */
    width: 220px;
    margin-top: 20px;
  }